<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img
          slot="imageProfile"
          class="img"
          :src="avatar"
        >
        <h4
          v-if="user"
          slot="title"
          class="title"
        >
          {{ user.first_name }} {{ user.last_name }}
        </h4>
        <md-field slot="content">
          <label>Enter Password</label>
          <md-input
            v-model="login.password"
            type="password"
            @keyup.enter="onLogin"
          />
        </md-field>
        <md-button
          slot="footer"
          href
          class="md-success md-round"
          @click="onLogin"
        >
          Unlock
        </md-button>
      </lock-card>
    </div>
  </div>
</template>
<script>
import { LockCard } from '@/components';

export default {
  components: {
    LockCard,
  },
  data() {
    return {
      login: {
        email: null,
        password: null,
      },
    };
  },
  computed: {
    avatar() {
      return this.user?.image_url || '/img/default-avatar.png';
    },
  },
  mounted() {
    localStorage.removeItem('token');
    this.login.email = this.user.email;

    if (!this.user) this.$router.push('/login');
  },
  methods: {
    onLogin() {
      this.request(
        this.$API.LOGIN,
        'post',
        this.login,
        (data) => {
          this.setSession(data);

          window.location.reload();

          this.$router.go(-1);
        },
        null,
        true,
      );
    },
  },
};
</script>
<style></style>
